// /**
//  * @namespace components
//  */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProLink from '../../shared/ProLink/ProLink';

import './WhatsHot.scss';

// /**
//  * @class WhatsHot
//  *
//  * WhatsHot module with configuration options.
//  *
//  * @memberof components
//  *
//  *
//  * @property {object} data - Primary object to insert data props into component
//  * @property {string} data.title - Display title from Protein
//  * @property {string} data.logicalName - Logical name of the module, used as the HTML node ID
//  * @property {array} data.blocks - Array of blocks for the grid layout
//  * @property {string|number} data.blocks.size - Bootstrap grid size - oneOf: 2, 3, 4, 5, 6
//  * @property {string} data.blocks.textPosition - sets the alignment of the title and subTitle
//  * @property {boolean=} data.blocks.dark - Sets a dark theme on the block
//  * @property {boolean=} data.blocks.transparent - Sets the background of the the text to be transparent
//  * @property {string=} data.blocks.backgroundImage - Link to the background image
//  * @property {string=} data.blocks.backgroundColor - Background color in valid CSS hexadecimal, rgb or string value
//  * @property {string} data.blocks.textPosition - Set's the position of the text within the element. One of: top, bottom
//  * @property {string=} data.blocks.titleColor - Color of the block link title in valid CSS hexadecimal, rgb or string value
//  * @property {string=} data.blocks.subTitleColor - Color of the block link subTitle in valid CSS hexadecimal, rgb or string value
//  * @property {string} data.blocks.link - The HREF link for the block level link
//  * @property {string=} data.blocks.title - Title text for the block link
//  * @property {string=} data.blocks.subTitle - subTitle text for the block link
//  *
//  * @example
//  * return (
//  *   <WhatsHot data={data} />
//  * )
//  */

// const WhatsHot = (props = {}) => {
//     const { data = {} } = props;
//     const { title, logicalName, blocks = [] } = data;

//     const blocksMarkup =
//         blocks && blocks.length > 0
//             ? blocks.map((block = {}, i) => {
//                   const {
//                       size = 2,
//                       textPosition,
//                       dark,
//                       transparent,
//                       backgroundColor,
//                       backgroundImage,
//                       subTitleColor,
//                       titleColor,
//                       title,
//                       subTitle,
//                       link
//                   } = block;

//                   return (
//                       <div key={i} className={`whats-hot-block col-md-${size}`}>
//                           <ProLink
//                               className={classNames({
//                                   'whats-hot-block-inner': true,
//                                   'block-link': true,
//                                   'animate-from-top': textPosition === 'top',
//                                   'animate-from-bottom': textPosition === 'bottom',
//                                   'block-link-dark': dark,
//                                   'block-link-light': !dark,
//                                   'block-link-transparent': transparent
//                               })}
//                               to={link}
//                               style={{
//                                   backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
//                                   backgroundColor
//                               }}
//                           >
//                               <div
//                                   className={classNames({
//                                       'block-link-text': true,
//                                       'block-link-top': textPosition === 'top',
//                                       'block-link-bottom': textPosition === 'bottom'
//                                   })}
//                               >
//                                   {subTitle && <h5 style={{ color: subTitleColor }}>{subTitle}</h5>}
//                                   {title && <h4 style={{ color: titleColor }}>{title}</h4>}
//                               </div>
//                           </ProLink>
//                       </div>
//                   );
//               })
//             : null;

//     return (
//         <div className="container-fluid module whats-hot" id={logicalName}>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-12 text-center">
//                         <h1>{title}</h1>
//                     </div>
//                 </div>
//             </div>
//             <div className="container whats-hot-block-wrapper">
//                 <div className="row">{blocksMarkup}</div>
//             </div>
//         </div>
//     );
// };

// WhatsHot.propTypes = {
//     data: PropTypes.shape({
//         title: PropTypes.string.isRequired,
//         logicalName: PropTypes.string,
//         blocks: PropTypes.arrayOf(
//             PropTypes.shape({
//                 size: PropTypes.oneOf([2, 3, 4, 5, 6, '2', '3', '4', '5', '6']).isRequired,
//                 textPosition: PropTypes.oneOf(['top', 'bottom']).isRequired,
//                 dark: PropTypes.bool,
//                 transparent: PropTypes.bool,
//                 backgroundImage: PropTypes.string,
//                 backgroundColor: PropTypes.string,
//                 subTitleColor: PropTypes.string,
//                 titleColor: PropTypes.string,
//                 title: PropTypes.string,
//                 subTitle: PropTypes.string,
//                 link: PropTypes.string.isRequired
//             })
//         )
//     })
// };

// export default WhatsHot;

import miniGazelle from '../../../helpers/miniGazelle/miniGazelle';

class WhatsHot extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        /* standard props */

        /**
         * standard props used in the style / function of the module.
         * Null if not specified
         **/

        const { data = {} } = this.props || {};
        const { title, logicalName, homepage, homePageLink } = data || {};

        /**
         * props specific to this module
         **/

        const blocks = this.props.data.blocks || [];

        let blocksMarkup;

        /* standard variant */

        /* homepage variant */
        if (homepage) {
            blocksMarkup = blocks.map((block, i) => {
                return (
                    <div
                        key={i}
                        className={classNames({
                            'whats-hot-block': true,
                            'col-lg-4': true,
                            'whats-hot-homepage-block': true,
                        })}
                    >
                        <ProLink
                            className={classNames({
                                'whats-hot-block-inner': true,
                                'whats-hot-homepage-block-inner': true,
                                'block-link': true,
                                'block-link-homepage-dark': block.dark,
                                'block-link-transparent': block.transparent,
                            })}
                            to={block.link}
                            style={{
                                backgroundImage: block.backgroundImage ? 'url(' + block.backgroundImage + ')' : null,
                                backgroundColor: block.backgroundColor,
                            }}
                        >
                            <div
                                className={classNames({
                                    'block-link-homepage-text': true,
                                })}
                            >
                                <h3
                                    className="h4 text-center tw-font-medium tw-my-0"
                                    style={{ color: block.titleColor }}
                                >
                                    {block.subTitle}
                                </h3>
                            </div>
                        </ProLink>
                    </div>
                );
            });
        } else {
            /* standard variant */
            blocksMarkup = blocks.map((block, i) => {
                return (
                    <div
                        key={i}
                        className={classNames({
                            'whats-hot-block': true,
                            'col-lg-2': block.size == 2,
                            'col-lg-3': block.size == 3,
                            'col-lg-4': block.size == 4,
                            'col-lg-5': block.size == 5,
                            'col-lg-6': block.size == 6,
                        })}
                    >
                        <ProLink
                            className={classNames({
                                'whats-hot-block-inner': true,
                                'block-link': true,
                                'animate-from-top': block.textPosition === 'top',
                                'animate-from-bottom': block.textPosition === 'bottom',
                                'block-link-dark': block.dark,
                                'block-link-light': !block.dark,
                                'block-link-transparent': block.transparent,
                            })}
                            to={block.link}
                            style={{
                                backgroundImage: block.backgroundImage ? 'url(' + block.backgroundImage + ')' : null,
                                backgroundColor: block.backgroundColor,
                            }}
                        >
                            <div
                                className={classNames({
                                    'block-link-text': true,
                                    'block-link-top': block.textPosition === 'top',
                                    'block-link-bottom': block.textPosition === 'bottom',
                                })}
                            >
                                {block.subTitle && (
                                    <h4 className="h4-bold tw-my-0" style={{ color: block.subTitleColor }}>
                                        {block.subTitle}
                                    </h4>
                                )}
                                {block.title && (
                                    <p className="h4" style={{ color: block.titleColor }}>
                                        {block.title}
                                    </p>
                                )}
                            </div>
                        </ProLink>
                    </div>
                );
            });
        }

        return (
            <div className="container-fluid module whats-hot" id={logicalName}>
                <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className="container whats-hot-block-wrapper">
                    <div className="row">{blocksMarkup}</div>
                    {homepage && (
                        <div className="button-wrapper ml-auto p-0 m-0">
                            <ProLink
                                to={homePageLink}
                                className={classNames({
                                    btn: true,
                                    'btn-lg': true,
                                    'btn-solutions-light': true,
                                    'view-all': true,
                                })}
                            >
                                {miniGazelle.label('viewAll', true)}
                            </ProLink>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default WhatsHot;
